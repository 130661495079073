
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "TodoItem",
  props: {
    item: {
      type: Object,
    },
  },
  setup(props) {
    const firstChar = computed(() => {
      return (props.item as any).content.substring(0, 1);
    });
    return {
      firstChar,
      headerStyle: {
        backgroundColor: (props.item as any).bgColor,
      },
    };
  },
});
