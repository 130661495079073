import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f86400ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wating-item-action" }
const _hoisted_2 = { class: "flex align-center item-header-wrapper" }
const _hoisted_3 = { class: "item-time flex-sub" }
const _hoisted_4 = { class: "item-content margin-top-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "item-header flex justify-center align-center",
        style: _normalizeStyle(_ctx.headerStyle)
      }, _toDisplayString(_ctx.firstChar), 5 /* TEXT, STYLE */),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.time), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.content), 1 /* TEXT */)
  ]))
}